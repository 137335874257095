import {AppListToSelectOptions, buildSelector, constToSelectOptions} from "../utils/Utils";

export const FeedbackFilter = (constants) => {
    return {
        ids: {
            type: 'input',
            value: '',
            placeholder: 'Ids',
            requestNormalizer: (data) => {
                return JSON.stringify(Array.from(data.matchAll(/\d+/g), m => parseInt(m[0])));
            },
        },
        app_name: buildSelector(AppListToSelectOptions(constants, "app_list"))
    };
};