import React, {Component} from "react";
import List from "../components/List/List";
import {ApiConstantsContext} from "../variables/ApiConstantsContext";
import {FeedbackFilter} from "../models/Feedback";


export default class FeedbackList extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
    }

    render() {
        let constants = this.context.constants;
        return (
            <List
                name="Feedbacks"
                listApiUrl='/admin/feedbacks'
                model={[
                    {col: 'Id', row: 'id'},
                    {col: 'app name', row: 'app_name', normalizer: (data) => {
                            return constants.app_list.items[data.app_name].title
                        }
                    },
                    {col: 'user id', row: 'user_id'},
                    {col: 'is paid', row: 'is_paid'},
                    {col: 'platform sign up', row: 'platform_sign_up'},
                    {col: 'consent received', row: 'consent_received'},
                    {col: 'email', row: 'email'},
                    {col: 'platform', row: 'platform'},
                    {col: 'app_version', row: 'app_version'},
                    {col: 'period', row: 'period'},
                    {col: 'message', row: 'message'},
                    {col: 'rating', row: 'rating'},
                    {col: 'group', row: 'group'},
                    {col: 'device_type', row: 'device_type'},
                    {col: 'type', row: 'type'},
                    {col: 'local', row: 'local'},
                    {col: 'created_at', row: 'created_at'},
                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={FeedbackFilter(constants)}
                userHasPermission={this.props.userHasPermission}
                disableActions
            >
            </List>

        );
    }
}
