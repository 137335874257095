import {Label} from "react-bootstrap";
import React from "react";

const mapSelect = (dict, key) => {
    return {value: key, label: dict[key]}
};

export const buildSelector = (options, type = 'select', requestNormalizer, responseNormalizer, md) => {
    return {
        type: type,
        validationRules: 'required',
        onChangeEvent: 'handleSelect',
        selectOptions: Object.keys(options).map(function (key) {
            return mapSelect(options, key)
        }),
        value: '',
        requestNormalizer:
      requestNormalizer || function (data) {
        if (data === null) {
          return null;
        }
        return type === "select" ? data["value"] : data.map((value) => {
              return value["value"];
            });
      },

        responseNormalizer: responseNormalizer || function (data) {
            return type === 'select' ? mapSelect(options, data) : data.map((value) => {
                return mapSelect(options, value)
            })
        },
        md: md || null
    }
}

export const constToSelectOptions = (constants, key) => {
    let data = constants[key] === undefined ? {} : constants[key].items || {}
    return Object.fromEntries(Object.entries(data).map(
        ([k, v], i) => [k, v.title]
    ))
}

export const AppListToSelectOptions = (constants) => {
    let key = "app_list"
    let data = constants[key] === undefined ? {} : constants[key].items || {}
    return Object.fromEntries(Object.entries(data).map(
        ([k, v], i) => [v.value, v.title]
    ))
}

export const toTitleCase = (str) => {
    return str.replace(/_/g, ' ').replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const getConstantTitle = (constants, group, item, default_value) => {
    let data = constants[group] === undefined ? {} : constants[group].items || {};
    if (item !== undefined) {
        data = data[item]
    }
    return (data || {})['title'] || default_value
}

export const generateBadgeRow = (title, options, style = "warning") => {
    return <span style={{"margin": "0"}}>
        {title}:
        {(options || []).map((value, index) => {
            return <strong><Label
                key={index}
                style={{"marginLeft": "4px"}}
                // User "badge alert-success" or "badge progress-bar-success"
                // For alternative badges
                // info, success, danger, warning, primary
                className={`alert-${style}`}>{value}
            </Label></strong>
        })}
    </span>
}