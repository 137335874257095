import Info from "./controllers/Info";
import defaults from "./defaults";
import FeedbackList from "./controllers/FeedbackList";

let g = defaults.userGroups

const dashboardRoutes = [
    {
        exact: true,
        path: "/feedbacks",
        name: "Feedbacks",
        icon: "pe-7s-check",
        component: FeedbackList,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/info",
        name: "Info",
        icon: "pe-7s-info",
        component: Info,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer, g.support]
    },

];

export default dashboardRoutes;
